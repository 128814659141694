import { useEffect, useState } from 'react';
import './App.css';
import PizzasMenu from './Menus/Comidas/pizzas';
import BaseMenu from './Menus/baseMenu';
import BaseMenuCG from './Menus/Bebidas/copoGarrafaMenu';

function App() {  

  const menuActive = "tab-pane fade show active";
  const menuUnactive = "tab-pane fade";

  const menuTopActive = "nav-link active";
  const menuTopUnactive = "nav-link";

  const [menu1, setMenu1] = useState(menuActive);
  const [menu2, setMenu2] = useState(menuUnactive);
  const [menu3, setMenu3] = useState(menuUnactive);   

  const [menuTop1, setMenuTop1] = useState(menuTopActive);
  const [menuTop2, setMenuTop2] = useState(menuTopUnactive);
  const [menuTop3, setMenuTop3] = useState(menuTopUnactive);   

  const handleClick = (id: number) => {   
    
    if (id == 1)
    {        
        setMenu1(menuActive);       
        setMenu2(menuUnactive);
        setMenu3(menuUnactive);
          
        setMenuTop1(menuTopActive);       
        setMenuTop2(menuTopUnactive);
        setMenuTop3(menuTopUnactive);
    }
    else if (id == 2){
        
        setMenu2(menuActive);       
        setMenu1(menuUnactive);
        setMenu3(menuUnactive);

        setMenuTop2(menuTopActive);       
        setMenuTop1(menuTopUnactive);
        setMenuTop3(menuTopUnactive);     
    }
    else if(id==3){
       
        setMenu3(menuActive);       
        setMenu1(menuUnactive);
        setMenu2(menuUnactive);

        setMenuTop3(menuTopActive);       
        setMenuTop1(menuTopUnactive);
        setMenuTop2(menuTopUnactive);        
    } 
}
  
  const handleClick1 = () => { handleClick(1); }
  const handleClick2 = () => { handleClick(2); }
  const handleClick3 = () => { handleClick(3); }

  useEffect(() => {
    const timer = setInterval(() => {
      window.location.reload();
    }, 300000); // 300000 ms = 5 minutes

    // Cleanup function to clear the timer when the component unmounts
    return () => clearInterval(timer);
  }, []); // Empty dependency array means this effect runs once on mount and cleanup on unmount

  
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() returns 0-11
  const currentDay = currentDate.getDate();

  const isHalloweenOrDayAfter = (currentMonth === 10 && currentDay === 31) || (currentMonth === 11 && currentDay === 1);


  return (
<div>
    <div className="container">

      <div className="row">
        <div className="col-md-12 text-center">
            <img className="d-block mx-auto mb-4" src="img/logo_MG.png" alt="" width="250" height="auto"/>
            <p>&nbsp;</p>
            <h2 className='colorMenuTop'>MENU</h2>
            <p className="intro">Um cocktail refrescante? Uma tosta de comer e chorar por mais? Uma pizza para partilhar?
                <br/>Descobre o menu Meeting & Greeting, chama-nos para tratarmos do teu pedido e desfruta em segurança!
            </p>            
        </div>
      </div>

        
      <div className="row">
        <div className="col-md-12">           

            <ul className="nav nav-pills mb-3 nav-fill" id="pills-tab" role="tablist">                
                 
                <li className="nav-item" >
                    <a onClick={handleClick1} className={menuTop1} id="pills-contact-tab" data-toggle="pill" href="javascript:void(0);" role="tab" aria-controls="pills-contact" aria-selected="false">
                        <img src="img/bebidas_off.svg" alt="Cocktails, cerveja, bebidas sem alcool, café..."/>
                        <p>Bebidas</p>
                    </a>
                </li>
                <li className="nav-item">
                <a  onClick={handleClick2} className={menuTop2} id="pills-profile-tab" data-toggle="pill" href="javascript:void(0);" role="tab" aria-controls="pills-profile" aria-selected="false">
                    <img src="img/comidas_off.svg" alt="Tostas, baguetes, doces..."/>
                    <p>Comidas</p>
                </a>
                </li>
                <li className="nav-item" >
                    <a onClick={handleClick3} className={menuTop3} id="pills-contact-tab" data-toggle="pill" href="javascript:void(0);" role="tab" aria-controls="pills-contact" aria-selected="false">
                        <img src="img/pizza_off.svg" alt="Pizzas"/>
                        <p>Pizzas</p>
                    </a>
                </li>
            </ul>
            
            <div className="tab-content" id="pills-tabContent">
                <div className={menu1} id="menu_bebidas" role="tabpanel">
                    <div className="tab-content margin20" id="pills-tabContent">
                        <div className="panel panel-default">                                
                            <BaseMenu nome="But first coffee" file="coffee.json" /> 
                            <BaseMenu nome="Bebidas" file="bebidas.json" />  
                            <BaseMenu nome="Cidras e Cervejas" file="cidraECervejas.json" />
                            <BaseMenu nome="Caipirinhas" file="caipirinhas.json" />                               
                            <BaseMenu nome="Gin" file="gin.json" />
                            <BaseMenuCG nome="Wine Not" file="copoGarrafa.json" /> 
                            <BaseMenu nome="Cocktail" file="cocktail.json" />
                            <BaseMenu nome="Sangrias" file="sangrias.json" />
                            <BaseMenu nome="Doses" file="doses.json" />                            
                        </div>
                    </div>    
                </div>                
                <div className={menu2} id="menu_comidas" role="tabpanel">
                    <div className="tab-content margin20" id="pills-tabContent">
                        <div className="panel panel-default">
                            
                            {isHalloweenOrDayAfter && (
                            <BaseMenu nome="Especial Halloween" file="halloween.json" /> )}

                            <BaseMenu nome="Bites" file="bites.json" /> 
                            <BaseMenu nome="Entradas para partilhar" file="entradas.json" /> 
                            <BaseMenu nome="Sandes" file="sandes.json" /> 
                            <BaseMenu nome="Tostas" file="tostas.json" /> 
                            <BaseMenu nome="Salgados" file="salgados.json" />
                            </div>                        
                        </div>    
                    </div>  
                </div> 
                <div className={menu3} id="menu_pizzas" role="tabpanel">
                    <div className="tab-content margin20" id="pills-tabContent">
                        <div  id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className="panel panel-default">
                                <PizzasMenu></PizzasMenu>                                    
                            </div>
                        </div>
                    </div>    
                </div>
                
            </div>
        </div> 
        
    
      
        <div className="row thanks">
            <div className="col-md-12 text-center">
                <h2>Obrigado pela visita!</h2>
            </div> 
        </div>
        <div className="row">
            <div className="col-md-12 text-center"> 
                <a href="https://www.facebook.com/MnG.portugal" target="_blank"><img src="img/FB_MG.svg" alt="facebook M&G" className='mgp'/></a>
                <a href="https://www.instagram.com/mg_portugal/" target="_blank"><img src="img/IG_MG.svg" alt="facebook M&G" className='mgp'/></a>
            </div>
        </div>
        
    </div>
      
      

    <footer>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"> 
                    <p>Meeting&Greeting &copy; 2024</p>
                    <p>Build with Telerik UI for React - www.telerik.com</p>
                </div>
            </div>
                
        </div>
    </footer>       
       
        
    
    

    </div>
  );
}

export default App;
