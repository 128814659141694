import React from 'react'; 
import MenuCG from '../MenuCG';

interface BaseMenuProps {
  nome: string;
  file: string;
}

const BaseMenuCG: React.FC<BaseMenuProps> = ({ nome, file }) => {   
  
    return (
        <div>
            {MenuCG(nome, file)}
        </div>
    );
}

export default BaseMenuCG;