import  { useState } from 'react';
import { ModelPizzas } from './Model/ModelPizzas';
 

function MenuCG(nomeMenu: string, jsonMenu: string) {         

    const [menuData, setMenuData] = useState<ModelPizzas[]>([]); 

    if (menuData.length === 0) {
        fetch('https://menu.jsmotta.com.br/tabelas/' + jsonMenu)
        .then(response => response.json())
        .then(data => {        
            const dados = data as ModelPizzas[];
            dados.map((menu, index) => menu.selecionado = false);                   
            setMenuData(dados);
        });
    }
    
    const [menuDataVisible, setmenuDataVisible] = useState(true);
    
    const togglemenuData = () => {
        setmenuDataVisible(!menuDataVisible);
      };

    const showMe = "panel-collapse" + (menuDataVisible ? 'collapse show openMainOpen' : 'collapse');
    const showMeCaption = menuDataVisible ? 'openMainOpen' : 'collapsed';
    const menuDataVisibleYN = menuDataVisible ? 'true' : 'false';

    
    const rowSelected : string = "row colorMenuSelected";
    const rowUnselected : string = "row";

    const changeMenu = (menu: ModelPizzas) => () => {
        const newMenuData = menuData.map((item) => {
            if (item === menu) {
                item.selecionado = !item.selecionado;
            }
            return item;
        });
        setMenuData(newMenuData);
    };
    
    return (
        <div>

        <div className="panel-heading" role="tab" id="headingEighteen">
        <h4 className="panel-title">
            <a onClick={togglemenuData} className={showMeCaption} data-toggle="collapse" data-parent="#accordion" href="javascript:void(0);" aria-expanded={menuDataVisibleYN} aria-controls="collapseEighteen">{nomeMenu}</a>
        </h4>
        </div>
        <div id="collapseEighteen" className={showMe} role="tabpanel" aria-labelledby="headingEighteen">
            <div className="panel-body">                                    
                <div className="openMenu">

                {menuDataVisible} { (
                        <div className="row">
                        <div className="col-md-8 col-8"> 
                            <p></p>
                        </div>
                        <div className="col-md-2 col-2 text-right"> 
                            <p>meia</p>
                        </div>
                        <div className="col-md-2 col-2 text-right"> 
                            <p>inteira</p>
                        </div>
                        </div>
                )}

                {menuDataVisible && (
                    menuData        
                    .sort((a, b) => a.nome.localeCompare(b.nome))            
                    .map((menu, index) => (                   
                        <div onMouseUp={changeMenu(menu)} className={menuData[index].selecionado ? rowSelected : rowUnselected} key={index}>
                            <div className="col-md-8 col-8"> 
                                <p>{menu.nome}<br /><i>{menu.descricao}</i></p>
                            </div>
                            <div className="col-md-2 col-2 text-right"> 
                                <p>{menu.meia}</p>
                            </div>
                            <div className="col-md-2 col-2 text-right"> 
                                <p>{menu.grande}</p>
                            </div>
                        </div>
                    ))
                )}
                </div>
            </div>
        </div>
      </div>
    );
  }

export default MenuCG;