import Menus from '../MenuPizzas';

function PizzasMenu() {    
  
    const nomeMenu = "Pizzas";
    const jsonMenu = "pizzas.json";    

    return (
        <div>
            {Menus(nomeMenu, jsonMenu)}
        </div>
    );
  }

export default PizzasMenu;