import React from 'react';
import Menus from './MenuGeral';

interface BaseMenuProps {
  nome: string;
  file: string;
}

const BaseMenu: React.FC<BaseMenuProps> = ({ nome, file }) => {   
  
    return (
        <div>
            {Menus(nome, file)}
        </div>
    );
}

export default BaseMenu;